@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,400;0,500;0,800;1,300&display=swap');




*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

body{
  font-family: 'Urbanist', sans-serif;
}

.container{
  max-width: 1170px;
  padding: 0px 20px;
  margin: auto;
}

.section{
  padding: 50px 0px;
}

/* .fa-brands{
  color: ;
} */

.shadow-img{
  filter: drop-shadow(10px 8px 6px #75737e);  
}

@media only screen and (max-width:768px){
  .menu{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #00000084;
    z-index: 100;
    display: none;
  }

  .menu ul{
    width: 50vw;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 200;
    background: rgb(0,21,30);
    background: linear-gradient(180deg, rgb(1, 77, 110) 0%, rgb(0, 23, 33) 100%);
    color: white;
    flex-direction: column;
    line-height: 30px;
    justify-content: center;
    align-items: center;
  }

  .show__menu {
    display: block;
  }

  .menuIcon{
    z-index: 300;
  }
 
  .hero-img{
    display: none;
  }
}
.close{
  display: none;
}

.gradient-box{
  background-color: #ffffff;
  background-image: linear-gradient(315deg, #99ced1 0%, #ffffff 74%);
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.nav-shadow{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;}

.colored{
  color: blue;
}

.remarks-card{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
  .card{
    /* box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; */
    border-radius: 20px 20px 0 0 ;
}

.button{
  box-shadow: inset 0px 0px 8px #0c5c8b;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
}

.dark{
  background: rgb(0,21,30);
  background: linear-gradient(180deg, rgb(0, 38, 53) 0%, rgb(0, 19, 28) 100%);
  color: rgb(209, 209, 209);
}

.darkCross{
  background: rgb(0,21,30);
  background: linear-gradient(0deg, rgb(0, 38, 53) 0%, rgb(0, 19, 28) 100%);
  color: rgb(209, 209, 209);
}

.light{
  background: #FFFFFF;
}

.lightText{
  color: #cfd3d6;
}



.menu-icon{
  filter: drop-shadow(0px 2px 6px hsl(0, 0%, 100%));
}

.shadow{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.shadowForDark{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.blackNav{
  background-color: #0a0a0a;
}

.boxInDark{
    background: rgb(0,21,30);
    background: linear-gradient(190deg, rgb(1, 77, 110) 0%, rgb(0, 34, 49) 100%);
    color:#cfd3d6;
}

.social-icons:hover{
  filter: drop-shadow(0px 10px 6px rgb(65, 104, 119));
  transform: scale(1.1);
  transform: ease-in-out;
  transition-duration: 300ms;
}